@font-face {
    font-family: 'PlateiaBold';
    src: url(./fonts/PlateiaBold.ttf);
}

@font-face {
    font-family: 'HelveticaRegular';
    src: url(./fonts/helvetica_regular.otf);
}

body {
    margin: 0;
    font-family: HelveticaRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    background: #111;
    overflow-x: hidden;
    font-size: 16px;
    color: #fff;
}

html {
    overflow-x: hidden;
}

.logo, .button-bar,
.button-submit {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #1B1B1C inset;
    transition: background-color 5000s ease-in-out 0s;
}

p {
    font-size: 16px;
}

a {
    text-decoration: none;
}

.App {
    min-height: 100vh;
}

main {
}

li {
    display: flex;
}

ul {
    padding: 0;
}

.header, .active-header {
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    height: 70px;
    width: calc(100% - 80px);
    z-index: 1000;
    overflow: hidden;
    border-bottom: 1px solid #fff;
    padding: 0 40px;
}

.button-bar:hover {
    background: #1B1B1C;
    color: dodgerblue;
}

.button-bar {
    border-radius: 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    height: 25px;
    transition: width .1s linear;
    cursor: pointer;

}

a > .logo {
    height: 71px;
}

.logo {
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    font-family: PlateiaBold, serif;
    line-height: 71px;
    height: 71px;
    margin-top: -12px;
}

.main-header-container {
    width: calc(100% - 63px);
    height: 100%;
    display: flex;
    justify-content: right;
    gap: 1%;
    align-items: center;
}

.header-container {
    width: 100%;
    height: 71px;
    align-items: center;
    display: flex;
}

.top-mobile-buttons {
    width: 100vw;
    justify-content: center;
    display: flex;
    opacity: 0;
    transition: opacity .1s;
}

.top-active-mobile-buttons {
    width: 100vw;
    justify-content: center;
    display: flex;
    opacity: 0;
}

.form__input-container {
    background-color: #1B1B1C;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    padding: 2px;
    width: 100%;
    overflow: hidden;
    display: flex
}

.form__input {
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
    border: 0;
    background: #1B1B1C;
    color: #fff;
    font-size: 16px;
    height: 30px;
    width: 100%;
}

.form__input:focus {
    outline: none !important;
}

.button-submit {
    padding: 10px 0;
    border-radius: 10px;
    border: none;
    background: #F6D139;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    width: 90px;
    height: 34px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}

.form-comment > .button-submit {
    padding: 10px
}

.button-submit:hover {
    background-color: #D4B017;
    opacity: .8
}

.main-container {
    margin: 40px 0;
    width: 100%;
    justify-content: center;
    display: flex;
    max-width: 800px;
}

.left-header-container {
    display: flex;
    width: 63px;
}

.right-header-container {
    justify-content: right;
    display: flex;
    cursor: pointer;
    width: 0;
    transition: opacity .2s;
    transition-delay: .8s;
}

.text-button-bar {
    overflow: hidden
}

.text-container-button-bar {
    width: 100%;
    margin-top: -2px;
}

.main {
    display: flex;
    gap: 10px;
    margin: 0 40px;
    justify-content: center;
}

.menu-right {
    display: none;
}



.link-social-container {
    cursor: pointer;
}

.link-social-container:hover {
    text-decoration: underline;
}

.link-social {
    transition: width .5s;
    overflow: hidden;
}

.slider-container {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap
}

.slide-number {
    width: 130px;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 30px;
    text-align: right
}

.slider {
    width: calc(100% - 150px);
    display: flex;
    overflow: hidden;
}

.slider-title {
    width: 100%;
    margin-left: 150px;
    display: flex;
    justify-content: space-between;
    font-size: 30px
}

.slide {
    transition: all 1s
}

.slide > img {
    width: 100%;
    height: 500px;
    object-fit: cover
}

.links-social {
    display: flex;
    margin-top: 30px;
    width: 100%
}

.comments {
    margin-left: 150px;
    max-width: calc(100% - 300px);
}

.section-title {
    display: flex;
    margin-top: 40px
}

.section-title h1 {
    border: 1px solid #fff;
    padding: 10px;
    line-height: 22px;
    height: 27px;
    border-radius: 20px;
    margin: 10px 0 0;
    white-space: nowrap
}

.comments > .section-title {
    margin: 0
}

.form-comment {
    display: flex;
    gap: 10px
}

.form {
    width: calc(100% - 300px);
    background: #F6D139;
    height: 450px;
    margin: 40px -40px 0;
    display: flex;
    justify-content: center;
    padding: 40px 190px;
    gap: 20px;
    flex-wrap: wrap;
}

.comment {
    margin: 20px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #555;
}

.link-social-container:nth-child(1) {
    width: 120px
}

.link-social-container:nth-child(2) {
    width: 100px
}

.link-social-container:nth-child(3) {
    width: 100px
}

.gradient-container {
    position: relative;
    bottom: 554px;
    height: 0
}

.form__input-group {
    width: calc(50% - 10px);
    height: 100px;
}

.form-title {
    color: #000;
    width: 100%;
    font-size: 40px
}

.form__input-lable {
    color: #000;
    font-size: 30px
}

.form__input-group > .button-submit {
    background: #000;
    color: #F6D139;
    width: 100%
}

@media (max-width: 1070px) {
    .form-title {
        font-size: 30px
    }

    .form {
        height: 700px;
        gap: 0;
    }

    .form__input-group {
        width: 100%;
    }

    .comments {
        max-width: calc(100% - 150px);
    }
}

@media (max-width: 720px) {
    .logo {
        margin-top: 10px;
    }

    .form-title {
        font-size: 25px
    }

    .form {
        width: 100%;
        padding: 40px;
    }

    .section-title {
        margin: 20px -20px 0
    }

    .comments {
        margin: 0 -20px;
        max-width: calc(100vw - 40px);
        width: calc(100vw - 40px);
    }

    .slide > img {
        height: 400px;
    }

    .slider-title {
        margin: 0
    }

    .slide-number {
        width: 100%;
        text-align: left;
    }

    .slider {
        width: 100%;
    }

    .slider-container {
        width: calc(100vw - 40px);
        margin: 20px -20px;
    }

    .active-header {
        position: fixed;
    }

    .header {
        transition: border 1s;
    }

    .menu-right {
        display: flex;
    }

    .active-header {
        margin: 0 -40px;
        padding: 0 40px;
        width: 100vw;
        overflow: hidden;
        height: 100vh;
        background: #111111;
        transition: height 1s;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
    }

    .top-active-mobile-buttons {
        opacity: 1;
        transition: opacity .1s;
    }

    .main {
        margin: 0 20px
    }

    .header {
        padding: 0 20px;
        width: calc(100% - 40px);
    }
}

.top-banner-description {
    position: relative;
    color: white;
    height: 0;
    top: 5vh;
    z-index: 500;
    padding: 0 60px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 1);
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    min-height: 100px
}

tr {
    border-bottom: 1px solid #fff;
    width: 100%
}

td {
    word-break: break-all;
    padding: 10px
}

td:nth-child(1) {
    width: 17%
}

td:nth-child(2) {
    width: 33%
}

td:nth-child(3) {
    width: 50%
}

.top-banner img {
    height: 550px;
    width: 100%;
    object-fit: cover;
}

.main-header-links {
    display: flex;
    transition: opacity .2s;
    transition-delay: .8s
}

.form-comment > .form__input-container {
    width: calc(100% - 100px);
}

.stroke {
    cursor: pointer
}
.strokes {
    display: flex;
    gap: 20px;
    margin: 5px 0 0 20px
}

.comment-user {
    display: flex;
    gap: 10px;
    align-content: center
}

.comment-user > img {
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-top: 5px
}

.comment-username {
    height: 30px;
    text-align: center;
    font-size: 18px;
}

.gradient {
    height: 551px;
    width: 100%;
}

.comment-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media (max-width: 720px) {
    a {
        height: 100px;
    }

    .button-bar {
        width: 100vw;
        height: 100px;
        font-size: 35px;
    }

    .active-header > .header-container {
        width: calc(100% - 80px);
        padding: 0 40px;
    }

    .top-banner {
        margin: 0 -40px;
    }

    table {
        margin: 0 -40px;
        width: 100vw;
    }

    .main-header-links {
        display: none;
    }
}

.h1-title-container {
    height: 70px;
    display: flex;
    width: 100vw;
}

.h1-title {
    overflow: hidden;
    transition: margin .5s;
    font-family: PlateiaBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', serif,
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 44px;
    margin: 0 0 20px;
    padding: 0;
}

@media (max-width: 780px) {

    .h1-title {
        font-size: 44px;
    }

    .top-banner-description {
        padding: 0 40px;
    }
}

@media (max-width: 560px) {
    .h1-title {
        font-size: 34px;
    }

    .top-banner-description {
        padding: 0 20px;
    }
}

@media (max-width: 360px) {
    .h1-title {
        font-size: 26px;
    }

    .top-banner-description {
        padding: 0 20px;
    }
}

@media (max-width: 280px) {
    .h1-title {
        font-size: 20px;
    }
}